.account {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 4;
  padding: 8px;
  color: var(--text-color-0);
  column-gap: var(--space-l);
  display: flex;

  &__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    // border: 1px solid var(--white);
    border-radius: var(--frame-border-radius);
    backdrop-filter: blur(7px);
    background: var(--frame-bar-color);
    height: 100%;
    padding: 8px;
    box-shadow: var(--box-shadow);
  }
}
