@font-face {
  font-family: "Pexel Grotesk";
  src: url("/fonts/PexelGrotesk-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Satoshi";
  src: url("/fonts/Satoshi-Medium.woff2") format("woff2");
  font-display: swap;
}

* {
  box-sizing: border-box;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html,
body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
input {
  margin: 0;
  font-family: var(--secondary-font);
}

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;

  scroll-behavior: smooth;

  overscroll-behavior-y: contain;
  overflow: hidden;
}

button {
  border: none;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  background: transparent;
}

/* Variables */
:root {
  --light-black: #121212;
  --black: black;
  --light-gray: whitesmoke;
  --gray: #b5b5b5;
  --dark-gray: #414141;
  --blue: #51a0d5;
  --dark-green: #063d0977;
  --green: #00a70877;
  --yellow: yellow;

  --text-xs: 12px;
  --text-s: 14px;
  --text-m: 16px;
  --text-l: 24px;
  --text-xl: 32px;

  --space-xxs: 2px;
  --space-xs: 4px;
  --space-s: 8px;
  --space-m: 12px;
  --space-l: 26px;
  --space-xl: 24px;
  --space-xxl: 32px;

  --primary-font: "Pexel Grotesk", "Courier New", monospace;
  --secondary-font: "Satoshi", sans-serif;

  --type-label: var(--text-s) var(--secondary-font);

  --overlay: transparentize(black, 0.2);
  --profile-image-size: 150px;

  --frame-border-radius: 18px;
}

html {
  &.dark {
    --box-shadow: 0px 4px 25px rgba(121, 121, 121, 0.28);
    --white: #ffffffba;

    --frame-bar-color: #292929d4;
    --frame-border-color: #292929d4;
    --frame-bar-text-color: var(--gray);
    --frame-color: var(--light-black);

    --overlay-color: rgba(0, 0, 0, 0.75);
    --icon-color: var(--gray);
    --text-color-0: var(--gray);
    --text-color-1: var(--light-gray);

    --highlight-color: #6828e6;
    --highlight-color-hovered: #45199e;
    --outline-color: var(--gray);

    --tab-border-color: #232323;
    --tab-background-color: #303030;

    --logo-color: var(--white);
    --background-color: linear-gradient(-45deg, #303030, #3b3b3b, #353535, #505050);
  }

  &.light {
    --box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
    --white: white;

    --frame-bar-color: var(--light-gray);
    --frame-color: var(--white);
    --frame-bar-text-color: var(--dark-gray);

    --frame-border-color: var(--light-gray);
    --overlay-color: rgba(0, 0, 0, 0.226);

    --highlight-color: var(--yellow);
    --highlight-color-hovered: rgba(241, 241, 4, 0.774);

    --icon-color: var(--dark-gray);
    --text-color-0: var(--black);
    --text-color-1: var(--dark-gray);
    --outline-color: var(--light-black);
    --logo-color: var(--light-black);

    --tab-border-color: #ececec;
    --tab-background-color: #e2e2e2;
    --background-color: linear-gradient(-45deg, #dadada, #c7c7c7, #c2c2c2, #ffffff);
  }
}

body {
  background: var(--background-color);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
